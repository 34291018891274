import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAppearance setShowRedactedContentRegions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAppearance getShowRedactedContentRegions]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTAppearance setRedactedContentColor:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setRedactedContentColor:(PTColorPt *)value</td></tr>
<tr><th>To</th><td class="declaration">- (void)setRedactedContentColor:(SWIGTYPE_ColorPt *)value</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTAppearance getRedactedContentColor]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTColorPt *)getRedactedContentColor</td></tr>
<tr><th>To</th><td class="declaration">- (SWIGTYPE_ColorPt *)getRedactedContentColor</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PTTool</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>PTTool</td></tr>
<tr><th>To</th><td>PTPDFViewCtrlToolDelegate</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.toolDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak) id&lt;PTTool&gt; toolDelegate</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak) id&lt;PTPDFViewCtrlToolDelegate&gt; toolDelegate</td></tr>
</table>
<br>
</div>

</div>


<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolType</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeNone</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeTextSelection</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeFormFilling</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeLinkFollowing</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeEraser</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolName</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolNameTextSelection</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolNameFormFilling</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolNameLinkFollowing</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolNameEraser</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolTypeFromName()</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolNameFromType()</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSignatureAnnotationIdentifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/FreehandCreateToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> FreehandCreateToolbarDelegate</div>
<div class="difference"><span class="status removed">Removed</span> -[FreehandCreateToolbarDelegate undoFreehandStroke:fromButton:]</div>
<div class="difference"><span class="status removed">Removed</span> -[FreehandCreateToolbarDelegate redoFreehandStroke:fromButton:]</div>
<div class="difference"><span class="status removed">Removed</span> -[FreehandCreateToolbarDelegate cancelFreehandToolbar:fromButton:]</div>
<div class="difference"><span class="status removed">Removed</span> -[FreehandCreateToolbarDelegate dismissFreehandToolbar:fromButton:]</div>
<div class="difference"><span class="status removed">Removed</span> FreehandCreateToolbar</div>
<div class="difference"><span class="status removed">Removed</span> FreehandCreateToolbar.undoEnabled</div>
<div class="difference"><span class="status removed">Removed</span> FreehandCreateToolbar.redoEnabled</div>
<div class="difference"><span class="status removed">Removed</span> FreehandCreateToolbar.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnalyticsHandlerAdapter.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnalyticsHandlerAdapter</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnalyticsHandlerAdapter</td></tr>
<tr><th>To</th><td>PTAnalyticsHandlerAdapter</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationPermission.h to Tools/PTAnnotationOptions.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PTAnnotationPermission</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PTAnnotationPermission</td></tr>
<tr><th>To</th><td>PTAnnotationOptions</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> +[PTAnnotationPermission permission]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (PTAnnotationPermission *)permission</td></tr>
<tr><th>To</th><td class="declaration">+ (PTAnnotationOptions *)options</td></tr>
</table>
<br>
</div>


<div class="difference"><span class="status modified">Modified</span> -[PTAnnotationPermission isEqualToAnnotationPermission:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)isEqualToAnnotationPermission:(PTAnnotationPermission *)permission</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)isEqualToAnnotationOptions:(PTAnnotationOptions *)options</td></tr>
</table>
<br>
</div>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotationToolbar.h to Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotBarButton</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonStickynote</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonHighlight</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonStrikeout</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonUnderline</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonSquiggly</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonSignature</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonFreehand</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonEraser</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonFreetext</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonArrow</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonLine</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonRectangle</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonEllipse</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonPolygon</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonCloud</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonPolyline</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonPan</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonClose</div>

<div class="difference"><span class="status added">Added</span> PTAnnotationToolbar.precedenceArray</div>
</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnnotationToolbarDelegate</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>AnnotationToolbarDelegate</td></tr>
<tr><th>To</th><td>PTAnnotationToolbarDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotationToolbar</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotationToolbar</td></tr>
<tr><th>To</th><td>PTAnnotationToolbar</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotationViewController.h to Tools/PTAnnotationViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnnotationViewControllerDelegate</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>AnnotationViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTAnnotationViewControllerDelegate</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> AnnotationViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotationViewController</td></tr>
<tr><th>To</th><td>PTAnnotationViewController</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotEditTool.h to Tools/PTAnnotEditTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnnotEditToolDelegate</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>AnnotEditToolDelegate</td></tr>
<tr><th>To</th><td>PTAnnotEditToolDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotEditTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotEditTool</td></tr>
<tr><th>To</th><td>PTAnnotEditTool</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotStyle.h to Tools/PTAnnotStyle.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKey</td></tr>
<tr><th>To</th><td>PTAnnotStyleKey</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyColor</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyColor</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyStrokeColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyStrokeColor</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyStrokeColor</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyFillColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyFillColor</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyFillColor</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyTextColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyTextColor</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyTextColor</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyThickness</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyThickness</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyThickness</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyOpacity</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyOpacity</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyOpacity</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleKeyTextSize</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleKeyTextSize</td></tr>
<tr><th>To</th><td>PTAnnotStyleKeyTextSize</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyleDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleDelegate</td></tr>
<tr><th>To</th><td>PTAnnotStyleDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> AnnotStyle</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyle</td></tr>
<tr><th>To</th><td>PTAnnotStyle</td></tr>
</table>
<br>

</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotStyleViewController.h to Tools/PTAnnotStyleViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> AnnotStyleViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTAnnotStyleViewControllerDelegate</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> AnnotStyleViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>AnnotStyleViewController</td></tr>
<tr><th>To</th><td>PTAnnotStyleViewController</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/ArrowCreate.h to Tools/PTArrowCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ArrowCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ArrowCreate</td></tr>
<tr><th>To</th><td>PTArrowCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/BookmarkUtils.h to Tools/PTBookmarkUtils.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> BookmarkUtils</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>BookmarkUtils</td></tr>
<tr><th>To</th><td>PTBookmarkUtils</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/BookmarkViewController.h to Tools/PTBookmarkViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> BookmarkViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>BookmarkViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTBookmarkViewControllerDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> BookmarkViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>BookmarkViewController</td></tr>
<tr><th>To</th><td>PTBookmarkViewController</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ChoiceFormViewController.h to Tools/PTChoiceFormViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ChoiceFormViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ChoiceFormViewController</td></tr>
<tr><th>To</th><td>PTChoiceFormViewController</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/CloudCreate.h to Tools/PTCloudCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> CloudCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>CloudCreate</td></tr>
<tr><th>To</th><td>PTCloudCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ColorDefaults.h to Tools/PTColorDefaults.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ColorDefaults</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ColorDefaults</td></tr>
<tr><th>To</th><td>PTColorDefaults</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ColorPickerViewController.h to Tools/PTColorPickerViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ColorPickerViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ColorPickerViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTColorPickerViewControllerDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ColorPickerViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ColorPickerViewController</td></tr>
<tr><th>To</th><td>PTColorPickerViewController</td></tr>
</table>
<br>


</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/CreateToolBase.h to Tools/PTCreateToolBase.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> CreateToolBase</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>CreateToolBase</td></tr>
<tr><th>To</th><td>PTCreateToolBase</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/DigitalSignatureTool.h to Tools/PTDigitalSignatureTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> DigitalSignatureTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>DigitalSignatureTool</td></tr>
<tr><th>To</th><td>PTDigitalSignatureTool</td></tr>
</table>
<br>


</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/DigSigView.h to Tools/PTDigSigView.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> DigSigView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>DigSigView</td></tr>
<tr><th>To</th><td>PTDigSigView</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/DigSigViewController.h to Tools/PTDigSigViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> DigSigViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>DigSigViewController</td></tr>
<tr><th>To</th><td>PTDigSigViewController</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> DigSigViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>DigSigViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTDigSigViewControllerDelegate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.findTextToolbarHidden</div>
</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.annotationToolbar</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.searchViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController showSearchViewController]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pageIndicatorViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.settingsViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.reflowViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.searchViewControllerHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.reflowHidden</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTDocumentOpening, PTPDFViewCtrlDelegate, ToolManagerDelegate</td></tr>
<tr><th>To</th><td>PTDocumentOpening, PTOverridable, PTPDFViewCtrlDelegate, PTToolManagerDelegate, UIPopoverPresentationControllerDelegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.toolManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) ToolManager *toolManager</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) PTToolManager *toolManager</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.thumbnailsViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) ThumbnailsViewController *thumbnailsViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nonnull) PTThumbnailsViewController *thumbnailsViewController</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.thumbnailSliderController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) ThumbnailSliderViewController *thumbnailSliderController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nonnull) PTThumbnailSliderViewController *thumbnailSliderController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/EditToolbar.h to Tools/PTEditToolbar.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> EditToolbarDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>EditToolbarDelegate</td></tr>
<tr><th>To</th><td>PTEditToolbarDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> EditToolbar</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>EditToolbar</td></tr>
<tr><th>To</th><td>PTEditToolbar</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/EllipseCreate.h to Tools/PTEllipseCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> EllipseCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>EllipseCreate</td></tr>
<tr><th>To</th><td>PTEllipseCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/Eraser.h to Tools/PTEraser.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> Eraser</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>Eraser</td></tr>
<tr><th>To</th><td>PTEraser</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/FindTextToolbar.h to Tools/PTFindTextToolbar.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> FindTextToolbarDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FindTextToolbarDelegate</td></tr>
<tr><th>To</th><td>PTFindTextToolbarDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> FindTextToolbar</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FindTextToolbar</td></tr>
<tr><th>To</th><td>PTFindTextToolbar</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/FloatingSigViewController.h to Tools/PTFloatingSigViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> FloatingSigViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FloatingSigViewController</td></tr>
<tr><th>To</th><td>PTFloatingSigViewController</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> FloatingSigViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FloatingSigViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTFloatingSigViewControllerDelegate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/FormFillTool.h to Tools/PTFormFillTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> FormFillTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FormFillTool</td></tr>
<tr><th>To</th><td>PTFormFillTool</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/FreeHandCreate.h to Tools/PTFreeHandCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> FreeHandCreateDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FreeHandCreateDelegate</td></tr>
<tr><th>To</th><td>PTFreeHandCreateDelegate</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> FreeHandCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FreeHandCreate</td></tr>
<tr><th>To</th><td>PTFreeHandCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/FreeTextCreate.h to Tools/PTFreeTextCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> FreeTextCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>FreeTextCreate</td></tr>
<tr><th>To</th><td>PTFreeTextCreate</td></tr>
</table>
<br>

</div>

<div class="differenceGroup">

<div class="difference"><span class="status added">Added</span> +[PTFreeTextCreate setRectForFreeText:withRect:pdfViewCtrl:isRTL:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/HalfModalPresentationManager.h to Tools/PTHalfModalPresentationManager.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> HalfModalPresentationManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>HalfModalPresentationManager</td></tr>
<tr><th>To</th><td>PTHalfModalPresentationManager</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/LineCreate.h to Tools/PTLineCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> LineCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>LineCreate</td></tr>
<tr><th>To</th><td>PTLineCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/LineEditTool.h to Tools/PTLineEditTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> LineEditTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>LineEditTool</td></tr>
<tr><th>To</th><td>PTLineEditTool</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/TrnMagnifierView.h to Tools/PTMagnifierView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> TrnMagnifierView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TrnMagnifierView</td></tr>
<tr><th>To</th><td>PTMagnifierView</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTNavigationListsViewController initWithToolManager:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithToolManager:(nonnull ToolManager *)toolManager</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithToolManager:(nonnull PTToolManager *)toolManager</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController.outlineViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) OutlineViewController *outlineViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) PTOutlineViewController *outlineViewController</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController.annotationViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) AnnotationViewController *annotationViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) PTAnnotationViewController *annotationViewController</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController.bookmarkViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) BookmarkViewController *bookmarkViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, strong, nonnull) PTBookmarkViewController *bookmarkViewController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/NoteEditController.h to Tools/PTNoteEditController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> NoteEditController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>NoteEditController</td></tr>
<tr><th>To</th><td>NoteEditController</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/OutlineViewController.h to Tools/PTOutlineViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> OutlineViewControllerDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>OutlineViewControllerDelegate</td></tr>
<tr><th>To</th><td>PTOutlineViewControllerDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> OutlineViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>OutlineViewController</td></tr>
<tr><th>To</th><td>PTOutlineViewController</td></tr>
</table>
<br>

</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/PTOverridable.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTOverridable</div>
<div class="difference"><span class="status added">Added</span> +[PTOverridable allocOverridden]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTOverrides.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTOverrides</div>
<div class="difference"><span class="status added">Added</span> +[PTOverrides overrideClass:withClass:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOverrides overriddenClassForClass:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PanTool.h to Tools/PTPanTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PanTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PanTool</td></tr>
<tr><th>To</th><td>PTPanTool</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/PolygonCreate.h to Tools/PTPolygonCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PolygonCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PolygonCreate</td></tr>
<tr><th>To</th><td>PTPolygonCreate</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PolylineCreate.h to Tools/PTPolylineCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PolylineCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PolylineCreate</td></tr>
<tr><th>To</th><td>PTPolylineCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/PolylineEditTool.h to Tools/PTPolylineEditTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PolylineEditTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PolylineEditTool</td></tr>
<tr><th>To</th><td>PolylineEditTool</td></tr>
</table>
<br>

</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/RectangleCreate.h to Tools/PTRectangleCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> RectangleCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>RectangleCreate</td></tr>
<tr><th>To</th><td>PTRectangleCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ResizeWidgetView.h to Tools/PTResizeWidgetView.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> Location</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>Location</td></tr>
<tr><th>To</th><td>PTResizeHandleLocation</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_topleft</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_topleft</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationTopLeft</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_top</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_top</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationTop</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_topright</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_topright</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationTopRight</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_right</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_right</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationRight</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_bottomright</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_bottomright</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationBottomRight</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_bottom</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_bottom</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationBottom</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> e_bottomleft</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_bottomleft</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationBottomLeft</td></tr>
</table>
<br>


<div class="difference"><span class="status modified">Modified</span> e_left</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>e_left</td></tr>
<tr><th>To</th><td>PTResizeHandleLocationLeft</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ResizeWidgetView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ResizeWidgetView</td></tr>
<tr><th>To</th><td>PTResizeWidgetView</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/RichMediaTool.h to Tools/PTRichMediaTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> RichMediaTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>RichMediaTool</td></tr>
<tr><th>To</th><td>PTRichMediaTool</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSearchViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextSearchViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> PTTextSearchViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/SelectionBar.h to Tools/PTSelectionBar.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> SelectionBar</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionBar</td></tr>
<tr><th>To</th><td>PTSelectionBar</td></tr>
</table>
<br>

</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/SelectionRectContainerView.h to Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> SelectionRectContainerView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectContainerView</td></tr>
<tr><th>To</th><td>PTSelectionRectContainerView</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/SelectionRectView.h to Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> SelectionRectViewDrawingMode</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectViewDrawingMode</td></tr>
<tr><th>To</th><td>PTSelectionRectViewDrawingMode</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> SelectionRectViewDrawingModeNone</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectViewDrawingModeNone</td></tr>
<tr><th>To</th><td>PTSelectionRectViewDrawingModeNone</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> SelectionRectViewDrawingModeLineNEStart</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectViewDrawingModeLineNEStart</td></tr>
<tr><th>To</th><td>PTSelectionRectViewDrawingModeLineNEStart</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> SelectionRectViewDrawingModeLineNWStart</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectViewDrawingModeLineNWStart</td></tr>
<tr><th>To</th><td>PTSelectionRectViewDrawingModeLineNWStart</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> SelectionRectView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>SelectionRectView</td></tr>
<tr><th>To</th><td>PTSelectionRectView</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSettingsViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidUpdateViewMode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidUpdateStickyToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidSelectThumbnails:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidRotateClockwise:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidRotateCounterClockwise:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate settingsViewControllerDidToggleNightMode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSettingsViewControllerDelegate viewerIsNightMode]</div>
<div class="difference"><span class="status added">Added</span> PTSettingsViewController</div>
<div class="difference"><span class="status added">Added</span> PTSettingsViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/TRNStampManager.h to Tools/PTStampManager.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TRNStampManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TRNStampManager</td></tr>
<tr><th>To</th><td>PTStampManager</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/StickyNoteCreate.h to Tools/PTStickyNoteCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> StickyNoteCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>StickyNoteCreate</td></tr>
<tr><th>To</th><td>PTStickyNoteCreate</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/TextHighlightCreate.h to Tools/PTTextHighlightCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextHighlightCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextHighlightCreate</td></tr>
<tr><th>To</th><td>PTTextHighlightCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/TextMarkupCreate.h to Tools/PTTextMarkupCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextMarkupCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextMarkupCreate</td></tr>
<tr><th>To</th><td>PTTextMarkupCreate</td></tr>
</table>
<br>

</div>



</div>

<div class="headerFile">
<div class="headerName">Tools/TextMarkupEditTool.h to Tools/PTTextMarkupEditTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextMarkupEditTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextMarkupEditTool</td></tr>
<tr><th>To</th><td>PTTextMarkupEditTool</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/TextSelectTool.h to Tools/PTTextSelectTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextSelectTool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextSelectTool</td></tr>
<tr><th>To</th><td>PTTextSelectTool</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/TextSquigglyCreate.h to Tools/PTTextSquigglyCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextSquigglyCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextSquigglyCreate</td></tr>
<tr><th>To</th><td>PTTextSquigglyCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/TextStrikeoutCreate.h to Tools/PTTextStrikeoutCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextStrikeoutCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextStrikeoutCreate</td></tr>
<tr><th>To</th><td>PTTextStrikeoutCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/TextUnderlineCreate.h to Tools/PTTextUnderlineCreate.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> TextUnderlineCreate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>TextUnderlineCreate</td></tr>
<tr><th>To</th><td>PTTextUnderlineCreate</td></tr>
</table>
<br>

</div>


</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailSliderViewController.h to Tools/PTThumbnailSliderViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ThumbnailSliderViewDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailSliderViewDelegate</td></tr>
<tr><th>To</th><td>PTThumbnailSliderViewDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ThumbnailsViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailsViewController</td></tr>
<tr><th>To</th><td>PTThumbnailsViewController</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailsViewController.h to Tools/PTThumbnailsViewController.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ThumbnailsViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailsViewController</td></tr>
<tr><th>To</th><td>PTThumbnailsViewController</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailViewCell.h to Tools/PTThumbnailViewCell.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ThumbnailViewCell</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailViewCell</td></tr>
<tr><th>To</th><td>PTThumbnailViewCell</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailViewLayout.h to Tools/PTThumbnailViewLayout.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> ThumbnailViewLayoutDefaultSpacing</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailViewLayoutDefaultSpacing</td></tr>
<tr><th>To</th><td>PTThumbnailViewLayoutDefaultSpacing</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ThumbnailViewLayout</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ThumbnailViewLayout</td></tr>
<tr><th>To</th><td>PTThumbnailViewLayout</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/Tool.h to Tools/PTTool.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> Tool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>Tool</td></tr>
<tr><th>To</th><td>PTTool</td></tr>
</table>
<br>

</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolManager.h to Tools/PTToolManager.h</div>

<div class="differenceGroup">

<div class="difference"><span class="status removed">Removed</span> ToolManagerInteractionToolNamesUserInfoKey</div>

</div>

<div class="differenceGroup">

<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.textAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.linkAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.freeTextAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.lineAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.squareAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.circleAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.polygonAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.polylineAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.highlightAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.underlineAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.squigglyAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.strikeOutAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.stampAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.caretAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.inkAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.popupAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.fileAttachmentAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.soundAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.movieAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.widgetAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.screenAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.printerMarkAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.trapNetAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.watermarkAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.threeDimensionalAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.redactAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.projectionAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.richMediaAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.arrowAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.signatureAnnotationPermission</div>
<div class="difference"><span class="status deprecated">Deprecated</span> ToolManager.cloudyAnnotationPermission</div>

</div>

<div class="differenceGroup">

<div class="difference"><span class="status added">Added</span> PTToolManager.textAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.linkAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.freeTextAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.lineAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.squareAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.circleAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.polygonAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.polylineAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.highlightAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.underlineAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.squigglyAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.strikeOutAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.stampAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.caretAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.inkAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.popupAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.fileAttachmentAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.soundAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.movieAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.widgetAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.screenAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.printerMarkAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.trapNetAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.watermarkAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.threeDimensionalAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.redactAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.projectionAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.richMediaAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.arrowAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.signatureAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.cloudyAnnotationOptions</div>

<div class="difference"><span class="status added">Added</span> PTToolManager.autoResizeFreeTextEnabled</div>

<div class="difference"><span class="status added">Added</span> PTToolManagerToolDidChangeNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerFormFieldDataModifiedNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerPreviousToolUserInfoKey</div>

</div>


<div class="differenceGroup">

<div class="difference"><span class="status modified">Modified</span> PTToolManager</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>PTToolManager</td></tr>
<tr><th>To</th><td>PTToolEvents</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> PTToolPermissionsDelegate</div>
<table>
<tr><th></th><th>Protocol Declaration</th></tr>
<tr><th>From</th><td>PTToolPermissionsDelegate</td></tr>
<tr><th>To</th><td>PTToolOptionsDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> PTToolSwitching</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTTool</td></tr>
<tr><th>To</th><td>PTPDFViewCtrlToolDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> PTToolSwitching.toolManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTToolManager,PTToolPermissionsDelegate&gt; toolManager</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTToolEvents,PTToolOptionsDelegate&gt; toolManager</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManager</td></tr>
<tr><th>To</th><td>PTToolManager</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> -[ToolManager annotationPermissionForAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>- (nullable PTAnnotationPermisison *)annotationPermissionForAnnotType:(PTExtendedAnnotType)annotType</td></tr>
<tr><th>To</th><td>- (nullable PTAnnotationOptions *)annotationOptionsForAnnotType:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>

 <div class="difference"><span class="status modified">Modified</span> ToolManagerDelegate</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>ToolManagerDelegate</td></tr>
<tr><th>To</th><td>PTToolManagerDelegate</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerAnnotationAddedNotification</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerAnnotationAddedNotification</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationAddedNotification</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerAnnotationModifiedNotification</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerAnnotationModifiedNotification</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationModifiedNotification</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerAnnotationRemovedNotification</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerAnnotationRemovedNotification</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationRemovedNotification</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerPermissionsDidChangeNotification</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerPermissionsDidChangeNotification</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationOptionsDidChangeNotification</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerAnnotationUserInfoKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerAnnotationUserInfoKey</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationUserInfoKey</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerPageNumberUserInfoKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerPageNumberUserInfoKey</td></tr>
<tr><th>To</th><td>PTToolManagerPageNumberUserInfoKey</td></tr>
</table>
<br>

<div class="difference"><span class="status modified">Modified</span> ToolManagerAnnotationNamesUserInfoKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>ToolManagerAnnotationNamesUserInfoKey</td></tr>
<tr><th>To</th><td>PTToolManagerAnnotationNamesUserInfoKey</td></tr>
</table>
<br>


</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PDFViewCtrlToolsUtil.h to Tools/PTToolsUtil.h</div>

<!-- <div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PDFViewCtrlToolsUtil</div>
<div class="difference"><span class="status removed">Removed</span> +[PDFViewCtrlToolsUtil toolImageNamed:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PDFViewCtrlToolsUtil toolImageNamed:inverted:]</div>
<div class="difference"><span class="status removed">Removed</span> PDFViewCtrlToolsUtil.toolsBundle</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsUtil</div>
<div class="difference"><span class="status added">Added</span> +[PTToolsUtil toolImageNamed:]</div>
<div class="difference"><span class="status added">Added</span> +[PTToolsUtil toolImageNamed:inverted:]</div>
<div class="difference"><span class="status added">Added</span> PTToolsUtil.toolsBundle</div>
</div>
 -->

<div class="difference"><span class="status modified">Modified</span> PDFViewCtrlToolsUtil</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td>PDFViewCtrlToolsUtil</td></tr>
<tr><th>To</th><td>PTToolsUtil</td></tr>
</table>
<br>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_CONSTRUCTOR</div>
<div class="difference"><span class="status added">Added</span> #def PT_CONSTRUCTOR_PRIORITY</div>
<div class="difference"><span class="status added">Added</span> #def PT_DESTRUCTOR</div>
<div class="difference"><span class="status added">Added</span> #def PT_DESTRUCTOR_PRIORITY</div>
</div>

</div>
</body>
</html>


`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}